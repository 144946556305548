<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :max-width="dialogWidth"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
    eager
    scollable
  >
    <v-card
      v-touch:swipe.left="cancel"
      class="d-flex flex-column noscroll charcoal"
      tile
    >
      <NotificationContainer />
      <v-sheet class="transparent noscroll">
        <BaseCloseDialogHeader
          :heading="pantryMode ? 'Add To Pantry' : 'Add Meal'"
          @cancel="cancel"
        />
        <BaseEdamanBadge :large="!isPhone" class="edamam" />
        <v-select
          height="60"
          solo
          dark
          label="Meal Type"
          v-model="selectedMealType"
          return-object
          :prepend-inner-icon="selectedMealType.icon"
          :items="mealTypes"
          item-text="meal"
          item-value="meal"
          class="py-0 mx-2"
          color="nicegreen"
          item-color="black"
          background-color="charcoal lighten-2"
          :menu-props="{
            closeOnClick: true,
            maxHeight: 310,
            transition: 'fab-transition'
          }"
        >
          <template
            v-slot:selection="{
              item,
              index,
              selected,
              disabled,
              remove
            }"
          >
            <v-chip
              class="silver lighten-3 pa-4 text-h6"
              :key="index"
              :input-value="item.value"
              :disabled="disabled"
            >
              <span class="black--text"> {{ item.meal }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-sheet>
      <v-sheet class="charcoal noscroll mt-n7">
        <v-card-text class="pa-0">
          <v-checkbox
            v-if="!pantryMode"
            dark
            color="nicegreen"
            class="pa-0 ml-2 mb-n2 enlarged-checkbox-left"
            off-icon="mdi-layers-search-outline"
            on-icon="mdi-layers-search"
            v-model="pantryOnly"
            @change="toggleFavorites"
          >
            <template v-slot:label>
              <span class="mt-1 text-caption"
                ><span
                  :class="pantryOnly ? 'nicegreen--text' : 'white--text'"
                  >{{ favoritesLabel() }}</span
                ></span
              >
            </template>
          </v-checkbox>
          <template v-if="pantryOnly">
            <v-card-actions class="pt-0 mb-2 mt-n5">
              <BaseSearch
                dark
                :items="favoritesList"
                :label="'pantry (' + favoritesList.length + ')'"
                what="name"
                @search_results="setResults"
              />
            </v-card-actions>
            <v-card-actions class="pa-0 mb-n4 mt-2">
              <v-spacer />
              <BaseActionButton
                x-large
                label="Use Selected"
                color="paper"
                icon="mdi-checkbox-multiple-marked-outline"
                text
                dark
                @clickedThis="usePantryItems()"
              />
            </v-card-actions>
          </template>
          <FlipCard
            v-if="!pantryOnly"
            dark
            labelFront="Add Recipe Instead"
            labelBack="Flip Back To Meal"
            @back="clearFoods()"
            @front="clearFoods()"
            class="mt-n5 charcoalTile"
          >
            <template v-slot:front>
              <v-sheet rounded class="charcoal charcoalTile py-2 mb-2">
                <v-card-actions class="py-0 mt-">
                  <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    height="70"
                    solo
                    dark
                    clearable
                    color="nicegreen"
                    background-color="charcoal lighten-2"
                    label="Search by name or barcode"
                    ref="search"
                    v-model="search"
                    class="pa-0 mr-n10"
                    @focus="$event.target.select()"
                    @click:clear="reset"
                    @keydown.enter="searchFood"
                  />
                  <v-spacer />
                  <v-icon
                    color="paper"
                    size="45"
                    @click="searchFood"
                    class="ml-n3 pa-0 mt-n5"
                  >
                    mdi-magnify
                  </v-icon>
                </v-card-actions>
                <v-card-actions v-if="isNativeApp()" class="pa-0 mt-n6">
                  <span class="silver--text ml-2"> OR</span>
                  <v-spacer />
                  <BaseActionButton
                    ripple
                    text
                    color="paper"
                    icon="mdi-qrcode-scan"
                    @clickedThis="manageScan()"
                    label="Scan Barcode"
                  />
                </v-card-actions>
              </v-sheet>
            </template>
            <template v-slot:back>
              <v-sheet rounded class="charcoal charcoalTile mx-2 mb-n10 py-2">
                <v-card-actions class="py-0">
                  <v-textarea
                    rows="2"
                    filled
                    dark
                    clearable
                    color="nicegreen"
                    label="Type ingredients (e.g 1 egg, 1 cup of flour)"
                    ref="search"
                    v-model="search"
                    class="pa-0 mr-n4"
                    @focus="$event.target.select()"
                    @click:clear="clearFoods"
                  />
                </v-card-actions>
                <v-card-actions class="pa-0 mt-n5">
                  <v-spacer />
                  <BaseActionButton
                    ripple
                    text
                    color="nicegreen"
                    icon="mdi-nutrition"
                    @clickedThis="searchNutrients()"
                    label="Get Nutrition"
                  />
                </v-card-actions>
              </v-sheet>
            </template>
          </FlipCard>
          <v-divider class="charcoal lighten-1 mx-5" v-if="!pantryOnly" />
        </v-card-text>
        <v-spacer />
        <v-card-text>
          <BaseLoading :loaded="loaded" label="Searching Food Database" />
        </v-card-text>
        <v-card-text v-if="barcode != null" class="silver--text px-2 pb-2">
          Barcode to be saved: {{ barcode }}
        </v-card-text>
        <template v-if="showSaveBarcodeOption">
          <v-card-text class="silver--text px-2 pb-2">
            We couldn't find this barcode. To save this barcode for future use,
            add the nutrition information and
            <span class="nicegreen--text">weight & meal name</span>.
            <v-spacer />
            Do you want to do so?
          </v-card-text>
          <v-sheet rounded class="charcoalTile transparent mx-2">
            <v-card-actions class="py-0">
              <v-text-field
                solo
                height="50"
                background-color="charcoal lighten-2"
                dark
                label="Meal Name"
                name="mealName"
                v-model="mealName"
                required
                type="text"
                prepend-inner-icon="mdi-pencil"
                :counter="50"
                @blur="mealName = capitalize(mealName)"
              />
            </v-card-actions>
            <v-row dense class="pl-2">
              <v-col cols="4">
                <v-text-field
                  dark
                  label="Weight(g)"
                  name="weight"
                  v-model="default_measure.weight"
                  class="enlarged-input-small mt-n2"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @input="dataEntered"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />

              <v-col cols="8" class="text-right">
                <BaseActionButton
                  text
                  color="orange"
                  icon="mdi-close-box-outline"
                  @clickedThis="saveBarcode(false)"
                  label="No"
                  class="mr-n4"
                />
                <BaseActionButton
                  text
                  color="nicegreen"
                  icon="mdi-content-save-check-outline"
                  @clickedThis="saveBarcode(true)"
                  label="Yes"
                  class="mr-n2"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </template>
        <v-spacer />
        <template v-if="foodList.length == 0 && favoritesList.length == 0">
          <v-sheet
            rounded
            class="charcoal paper--text mx-2 mt-n5 charcoalTileMenu"
          >
            <v-card-text
              v-if="noresults && nutrients == null"
              class="silver--text caption pa-2"
            >
              Your search yielded no results. Please check your search term and
              try again.
            </v-card-text>

            <template v-if="reUse || pantryMode">
              <v-card-actions class="pb-0 px-2">
                <v-text-field
                  solo
                  height="50"
                  background-color="charcoal lighten-2"
                  dark
                  label="Meal Name"
                  name="mealName"
                  v-model="mealName"
                  required
                  type="text"
                  prepend-inner-icon="mdi-pencil"
                  :counter="50"
                  @blur="mealName = capitalize(mealName)"
                />
              </v-card-actions>
            </template>
            <v-card-actions class="px-2 pb-0 mb-2">
              <span :class="textSizeXLarge + ' paper--text'">
                {{ Math.round(total_calories) }}
              </span>
              <span class="silver--text  ml-1">
                KCal
              </span>
              <v-spacer />
              <v-switch
                v-if="!pantryMode"
                dark
                class="pa-0 ma-0 mb-n2"
                color="nicegreen"
                v-model="reUse"
                label="Add To Pantry"
              />
            </v-card-actions>

            <v-card-text class="pa-2 py-0 mb-n2 mt-n2">
              <v-row no-gutters>
                <v-col cols="6" class="pr-1">
                  <v-progress-linear
                    height="20"
                    rounded
                    background-color="charcoal lighten-1"
                    color="hybrid lighten-1"
                    :value="fats_percentage"
                  >
                    <template v-slot:default="{ value }">
                      <strong class="paper--text text-left"
                        >Fats {{ value }}%</strong
                      >
                    </template>
                  </v-progress-linear>
                  <v-text-field
                    dark
                    solo
                    background-color="charcoal lighten-2"
                    color="hybrid lighten-2"
                    ref="fats"
                    v-model="fats"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @input="dataEntered"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="pl-1">
                  <v-progress-linear
                    height="20"
                    rounded
                    background-color="charcoal lighten-1"
                    color="nicegreen darken-1"
                    :value="proteins_percentage"
                  >
                    <template v-slot:default="{ value }">
                      <strong class="paper--text">
                        <span class="paper--text">Protein</span>
                        {{ value }}%
                      </strong>
                    </template>
                  </v-progress-linear>
                  <v-text-field
                    dark
                    solo
                    background-color="charcoal lighten-2"
                    color="nicegreen darken-1"
                    ref="proteins"
                    v-model="proteins"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @input="dataEntered"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-n5">
                <v-col cols="6" class="pr-1">
                  <v-progress-linear
                    height="20"
                    rounded
                    background-color="charcoal lighten-1"
                    color="gold"
                    :value="carbs_percentage"
                  >
                    <template v-slot:default="{ value }">
                      <strong class="paper--text">
                        <span class="paper--text">Total Carbs</span>
                        {{ value }}%
                      </strong>
                    </template>
                  </v-progress-linear>
                  <v-text-field
                    dark
                    solo
                    background-color="charcoal lighten-2"
                    color="gold"
                    ref="carbs"
                    v-model="carbs"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @input="dataEntered"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="pl-1">
                  <v-progress-linear
                    height="20"
                    rounded
                    background-color="charcoal lighten-1"
                    color="gold"
                    :value="0"
                  >
                    <template v-slot:default="{ value }">
                      <strong class="paper--text">
                        <span class="paper--text">Fiber</span>
                      </strong>
                    </template>
                  </v-progress-linear>
                  <v-text-field
                    dark
                    solo
                    background-color="charcoal lighten-2"
                    color="gold"
                    ref="fiber"
                    v-model="fiber"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @input="dataEntered"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-row no-gutters class="mt-n5">
                  <v-col cols="6" class="pr-1">
                    <v-progress-linear
                      height="20"
                      rounded
                      background-color="charcoal lighten-1"
                      color="gold"
                      :value="0"
                    >
                      <template v-slot:default="{ value }">
                        <strong class="paper--text">
                          <span class="paper--text">Sugar Alcohol</span>
                        </strong>
                      </template>
                    </v-progress-linear>
                    <v-text-field
                      dark
                      solo
                      background-color="charcoal lighten-2"
                      color="gold"
                      ref="sugara"
                      v-model="sugara"
                      class="enlarged-input-small"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      prepend-inner-icon="mdi-weight-gram"
                      @input="dataEntered"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-spacer />

                  <v-col cols="3" class="text-right">
                    <v-icon
                      class="mt-3 mr-n2"
                      label="More Nutrients"
                      size="55"
                      color="paper"
                      @click="showNutrients()"
                    >
                      mdi-dots-horizontal-circle-outline
                    </v-icon>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
          </v-sheet>
        </template>
        <v-card-text class="pa-0 charcoal">
          <template v-if="loaded">
            <template v-if="favoritesList.length > 0 && loaded">
              <v-sheet
                v-for="(foodItem, index) in favoritesList"
                :key="index"
                class="mx-2 mb-4 charcoal charcoalTileMenu"
              >
                <v-card class="transparent mb- pb-2" tile>
                  <v-card-actions class="pa-0">
                    <v-spacer />
                    <v-checkbox
                      class="mr-n2 pa-0 enlarged-checkbox"
                      color="nicegreen"
                      dark
                      label=""
                      on-icon="mdi-checkbox-marked-outline"
                      v-model="foodItem.selected"
                    />
                  </v-card-actions>
                  <v-card-text class="silver--text px-2 py-0 mt-n5">
                    <span :class="textSize + ' paper--text'">
                      {{ foodItem.name }}
                    </span>
                  </v-card-text>
                  <v-card-actions :class="textSizeLarge + ' paper--text py-0'">
                    {{ foodItem.calories }}
                    <span class="caption ml-1 mt-1 silver--text"
                      >kCalories</span
                    >
                    <v-spacer />
                    <span :class="textSizeSmall + ' silver--text'">
                      {{ foodItem.qty }} {{ foodItem.unit }}
                    </span>
                  </v-card-actions>
                  <v-card-text :class="textSizeSmall + ' px-2 pt-0'">
                    <v-spacer />

                    <span class="hybrid--text text--lighten-3">
                      {{ foodItem.fats }}g fats,
                    </span>
                    <span class="gold--text"> {{ foodItem.carbs }}g carbs</span
                    >,
                    <span class="nicegreen--text">
                      {{ foodItem.proteins }}g proteins
                    </span>
                    <template v-if="foodItem.unit != null && foodItem.qty > 0">
                      <v-spacer />
                    </template>
                    <v-spacer />
                  </v-card-text>
                  <v-card-actions class="pa-0 mt-4">
                    <v-icon
                      color="paper"
                      large
                      @click="selectFoodItem(0.25, foodItem)"
                      class="ml-0 pa-0 mt-n5"
                    >
                      mdi-circle-slice-2
                    </v-icon>
                    <v-spacer />
                    <v-icon
                      color="paper"
                      large
                      @click="selectFoodItem(0.33, foodItem)"
                      class="ml-0 pa-0 mt-n5"
                    >
                      mdi-circle-slice-3
                    </v-icon>
                    <v-spacer />
                    <v-icon
                      color="paper"
                      large
                      @click="selectFoodItem(0.5, foodItem)"
                      class="ml-0 pa-0 mt-n5"
                    >
                      mdi-circle-slice-4
                    </v-icon>
                    <v-spacer />
                    <v-icon
                      color="paper"
                      large
                      @click="selectFoodItem(0.75, foodItem)"
                      class="ml-0 pa-0 mt-n5"
                    >
                      mdi-circle-slice-6
                    </v-icon>
                    <v-spacer />
                    <v-icon
                      color="paper"
                      large
                      @click="selectFoodItem(1, foodItem)"
                      class="ml-0 pa-0 mt-n5"
                    >
                      mdi-circle-slice-8
                    </v-icon>
                    <v-spacer />
                    <v-icon
                      color="paper"
                      large
                      @click="selectFoodItem(2, foodItem)"
                      class="ml-0 pa-0 mt-n5"
                    >
                      mdi-circle-double
                    </v-icon>
                    <v-spacer />
                  </v-card-actions>

                  <v-row class="px-2 mt-2 text-left">
                    <v-col cols="5">
                      <span class="paper--text">
                        Multiplier
                      </span>
                      <v-text-field
                        solo
                        background-color="charcoal lighten-2"
                        height="50"
                        dense
                        dark
                        color="nicegreen"
                        label="Multiplier"
                        ref="quantity"
                        v-model="foodItem.fraction"
                        class="enlarged-input"
                        type="number"
                        pattern="[0-9]*"
                        inputmode="decimal"
                        prepend-inner-icon="mdi-close-circle-multiple-outline"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                    <v-spacer />
                  </v-row>
                </v-card>
              </v-sheet>
            </template>

            <template v-if="loaded && foodList.length > 0">
              <v-sheet
                v-for="(foodItem, index) in foodList"
                :key="index"
                class="mx-1  charcoal charcoalTileMenu mb-4"
              >
                <v-card-actions class="pb-0 ">
                  <span class="charcoal--text text--lighten-3">
                    {{ foodItem.food.category }},
                    {{ foodItem.food.categoryLabel }}
                  </span>
                  <v-spacer />
                  <v-checkbox
                    class="mt-2 mr-n5 pa-0 enlarged-checkbox"
                    color="nicegreen"
                    dark
                    label=""
                    @change="resetSelected(foodItem)"
                    v-model="foodItem.selected"
                  />
                </v-card-actions>
                <v-card
                  class="transparent  mt-n6 mb-0"
                  tile
                  :disabled="!foodItem.selected"
                >
                  <v-card-text class="silver--text px-2 py-0">
                    <span :class="textSize + ' paper--text'">
                      {{ foodItem.food.label }}
                    </span>
                    <span v-if="foodItem.food.brand" class="success--text">
                      <v-spacer />
                      {{ foodItem.food.brand }}
                    </span>
                    <v-spacer />
                  </v-card-text>
                  <v-card-actions :class="textSizeLarge + ' paper--text py-0'">
                    {{ foodItem.calories }}
                    <span class="caption ml-1 mt-1 mr-1 silver--text"
                      >kCalories</span
                    >
                    <span class="caption mt-1 silver--text">for 100 Grams</span>
                    <v-spacer />
                  </v-card-actions>
                  <v-card-text :class="textSizeSmall + ' px-2 pt-0 pb-1'">
                    <v-spacer />
                    <span class="nicegreen--text">
                      {{ foodItem.proteins }}g proteins,
                    </span>
                    <span class="hybrid--text text--lighten-3">
                      {{ foodItem.fats }}g fats,
                    </span>
                    <span class="gold--text"> {{ foodItem.carbs }}g carbs</span>
                  </v-card-text>
                  <v-row class="px-2" dense v-if="foodItem.selected">
                    <v-col cols="7">
                      <v-select
                        solo
                        height="60"
                        dense
                        color="nicegreen"
                        item-color="black"
                        background-color="charcoal lighten-2"
                        dark
                        label="Select Unit"
                        v-model="selectedUnit"
                        return-object
                        :items="foodItem.measures"
                        item-text="label"
                        item-value="uri"
                        @focus="normalizeMeasures(foodItem)"
                        :menu-props="{
                          closeOnClick: true,
                          maxHeight: 300,
                          transition: 'fab-transition'
                        }"
                        prepend-inner-icon="mdi-gesture-swipe-vertical"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="5">
                      <v-text-field
                        height="60"
                        solo
                        dark
                        clearable
                        color="nicegreen"
                        background-color="charcoal lighten-2"
                        v-if="selectedUnit != null"
                        dense
                        label="Quantity/Weight"
                        ref="quantity"
                        v-model="qty"
                        class="enlarged-input-small"
                        type="number"
                        pattern="[0-9]*"
                        inputmode="decimal"
                        prepend-icon="mdi-weight"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                  </v-row>
                  <v-card-actions
                    v-if="selectedUnit != null && qty > 0 && foodItem.selected"
                    class="px-0 pt-1 mt-n5 mr-1"
                  >
                    <v-spacer />
                    <BaseActionButton
                      label="Add Food"
                      color="paper"
                      icon="mdi-plus-circle-outline"
                      text
                      dark
                      class="paper--text"
                      @clickedThis="useMeal(foodItem.food)"
                    />
                  </v-card-actions>
                </v-card>
              </v-sheet>
            </template>
          </template>
        </v-card-text>
        <v-card-actions v-if="pantryOnly" class="pa-0 mt-2">
          <v-spacer />
          <BaseActionButton
            x-large
            label="Use Selected"
            color="paper"
            icon="mdi-checkbox-multiple-marked-outline"
            text
            dark
            @clickedThis="usePantryItems()"
          />
        </v-card-actions>
        <v-spacer />

        <v-row
          class="pa-0 mx-3 mt-n4"
          v-if="!pantryMode && !pantryOnly && !foodList.length > 0"
        >
          <v-datetime-picker
            class="paper--text"
            label="Meal Date & Time"
            v-model="datetime"
            :text-field-props="textFieldProps"
            :date-picker-props="dateProps"
            :time-picker-props="timeProps"
            timeFormat="HH:mm"
            dateFormat="yyyy-MM-dd"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-row>
      </v-sheet>
      <v-spacer />
      <v-card-actions class="pa-0" v-if="!pantryOnly && !foodList.length > 0">
        <v-btn
          tile
          v-if="!pantryMode"
          height="70"
          large
          label="Save Meal"
          color="nicegreen"
          block
          tonal
          @click="saveMeal()"
        >
          <v-icon color="black" large>
            mdi-content-save-all
          </v-icon>
          <span class="text-h6"> Save</span>
        </v-btn>
        <v-btn
          v-else
          tile
          height="70"
          large
          label="Add To Pantry"
          color="nicegreen"
          block
          tonal
          @click="addToPantry()"
        >
          <v-icon color="black" large>
            mdi-plus-circle-outline
          </v-icon>
          <span class="text-h6"> Add To Pantry</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <AddNutrients
      :show="showNutrientsDialog"
      :nutrients="nutrients.totalNutrients"
      @cancel="showNutrientsDialog = false"
      @done="updateNutrients"
    />
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import moment from 'moment/src/moment'
import { isMobile } from 'mobile-device-detect'

const NotificationContainer = () =>
  import(/* webpackPrefetch: true */ '@/components/NotificationContainer.vue')
const FlipCard = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/FlipCard.vue')

const AddNutrients = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddNutrients.vue')

export default {
  components: { NotificationContainer, FlipCard, AddNutrients },
  name: 'AddMeal',
  mixins: [validationMixin, util],
  data() {
    return {
      mobile: isMobile,
      textFieldProps: {
        dark: true,
        solo: true,
        'background-color': 'charcoal lighten-1',
        prependInnerIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'nicegreen'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'nicegreen',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      barcode: null,
      fraction: 1,
      loaded: false,
      reUse: false,
      isNow: true,
      noresults: false,
      carbs: 0,
      netcarbs: 0,
      proteins: 0,
      fats: 0,
      fiber: 0,
      sugara: 0,
      sugar: 0,
      proteins_calories: 0,
      fats_calories: 0,
      carbs_calories: 0,
      proteins_percentage: 0,
      fats_percentage: 0,
      carbs_percentage: 0,
      sugara_calories: 0,
      fiber_calories: 0,
      total_calories: 0,
      foodId: null,
      search: '',
      recipeName: '',
      selectedUnit: null,
      nutrients: null,
      qty: 1,
      favoritesNum: 0,
      pantryOnly: false,

      showNutrientsDialog: false,
      showSaveBarcodeOption: false,
      mealName: '',
      selectedMealType: {},
      mealTypes: [],
      foodList: [],
      favoritesList: [],
      loadedFavoritesList: [],
      datetime: null,
      correctionFactor: 1,
      default_measure: {
        label: 'Serving',
        weight: 0
      },
      measures: [
        {
          label: 'Gram',
          weight: 1
        },
        { label: 'Ounce', weight: 28.349523125 },
        { label: 'Pound', weight: 453.59237 },
        { label: 'Kilogram', weight: 1000 }
      ],
      emptyNutrients: {
        PROCNT: { quantity: 0 },
        FAT: { quantity: 0 },
        CHOCDF: { quantity: 0 },
        FIBTG: { quantity: 0 },
        'Sugar.alcohol': { quantity: 0 }
      }
    }
  },
  beforeMount() {
    this.reset()
    this.loadMealTypes()
    this.nutrients = {}
    this.nutrients.totalNutrients = {}
  },
  mounted() {
    this.nutrients.totalNutrients = JSON.parse(
      JSON.stringify(this.emptyNutrients)
    )
    this.$nextTick(() => {
      window.addEventListener('barcodescanned', this.barcodeScanned)
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('barcodescanned', this.barcodeScanned)
    window.removeEventListener('message', this.receiveMessage)
  },
  validations: {
    value: {
      required
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    pantryMode: {
      type: Boolean,
      default: false
    },
    day: {
      type: String
    }
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !(this.imperial > 0 || this.metric > 0) &&
        errors.push('Value is required.')
      return errors
    },
    ...appConfig
  },
  watch: {
    day(val) {
      if (val != null) {
        this.datetime = moment(val).format('yyyy-MM-DD HH:mm')
      } else {
        let formatted = moment().format('yyyy-MM-DD HH:mm')
        this.datetime = formatted
      }
    },
    show(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (!val) this.reset()
    },
    showNutrientsDialog(val) {
      this.$store.dispatch('session/toggleModal', val)
    },

    search(newVal) {
      if (newVal.match(/^[0-9 ]+$/)) {
        this.search = newVal.replace(/\s+/g, '')
        this.barcodeMode = true
      } else {
        this.barcodeMode = false // Reset barcodeMode if the condition is not met
      }
    }
  },

  methods: {
    selectFoodItem(multiplier, foodItem) {
      foodItem.fraction = multiplier
      foodItem.selected = true
    },
    normalizeMeasures(item) {
      item.measures.forEach(el => {
        if (!el.label) el.label = 'Default'
      })
    },
    setResults(value) {
      if (value.length > 0) this.favoritesList = value
      else this.favoritesList = this.loadedFavoritesList
    },
    updateNutrients(event) {
      this.nutrients.totalNutrients = event.nutrients
      this.setNutrients()
      if (event.changed) this.calculateCalories()
      this.showNutrientsDialog = false
    },
    setNutrients() {
      if (this.nutrients.totalNutrients.PROCNT) {
        this.proteins = this.twoDigits(
          this.nutrients.totalNutrients.PROCNT.quantity
        )
      }
      if (this.nutrients.totalNutrients.FAT) {
        this.fats = this.twoDigits(this.nutrients.totalNutrients.FAT.quantity)
      }
      if (this.nutrients.totalNutrients.CHOCDF) {
        this.carbs = this.twoDigits(
          this.nutrients.totalNutrients.CHOCDF.quantity
        )
      }

      if (this.nutrients.totalNutrients.FIBTG) {
        this.fiber = this.twoDigits(
          this.nutrients.totalNutrients.FIBTG.quantity
        )
      }

      if (this.nutrients.totalNutrients['Sugar.alcohol']) {
        this.sugara = this.twoDigits(
          this.nutrients.totalNutrients['Sugar.alcohol'].quantity
        )
      }
    },
    showNutrients() {
      this.showNutrientsDialog = true
    },
    manageScan() {
      this.enableBarcodeScan()
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showNutrientsDialog) this.showNutrientsDialog = false
        else this.cancel()
      }
    },
    barcodeScanned(event) {
      if (
        event.detail.message == 'BARCODE-WAS-FOUND' &&
        event.detail.key == 'jyQ8aCYHBy'
      ) {
        this.message = event.detail.messageText
        this.search = event.detail.data
        this.barcodeMode = true
        this.searchFood()
      } else if (
        event.detail.message == 'CANCEL_SCAN' &&
        event.detail.key == 'jyQ8aCYHBy'
      )
        this.message = event.detail.messageText
      else null
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    resetSelected(item) {
      if (item.selected) {
        this.mealName = item.food.label
        this.foodId = item.food.foodId
        this.selectedUnit = null
        this.qty = 1
      }
      this.selectedUnit = item.default_measure
      //unselect everything else
      this.foodList.forEach(el => {
        if (
          item.food.foodId != el.food.foodId ||
          el.food.label != item.food.label
        )
          el.selected = false
      })
    },
    resetNutrients() {
      this.nutrients = {}
      this.nutrients.totalNutrients = JSON.parse(
        JSON.stringify(this.emptyNutrients)
      )
    },
    reset() {
      this.datetime = ''
      this.mealName = ''
      this.proteins = 0
      this.carbs = 0
      this.fats = 0
      this.fiber = 0
      this.sugara = 0
      this.sugar = 0
      this.fats_calories = 0
      this.carbs_calories = 0
      this.proteins_calories = 0
      this.sugara_calories = 0
      this.total_calories = 0
      this.fats_percentage = 0
      this.carbs_percentage = 0
      this.proteins_percentage = 0
      this.reUse = false
      this.isNow = true
      this.loaded = true
      this.search = ''
      this.barcode = null
      this.barcodeMode = false
      this.showSaveBarcodeOption = false
      this.default_measure.weight = 0
      this.resetNutrients()
      this.clearFavorites()
      this.clearFoods()
    },
    dataEntered() {
      this.nutrients.totalNutrients.PROCNT.quantity = this.proteins
      this.nutrients.totalNutrients.FAT.quantity = this.fats
      this.nutrients.totalNutrients.CHOCDF.quantity = this.carbs
      if (this.nutrients.totalNutrients.FIBTG) {
        this.nutrients.totalNutrients.FIBTG.quantity = this.fiber
      }

      if (!this.nutrients.totalNutrients['Sugar.alcohol'])
        this.nutrients.totalNutrients['Sugar.alcohol'] = {}
      this.nutrients.totalNutrients['Sugar.alcohol'].quantity = this.sugara
      this.calculateCalories(false)
    },
    calculateCalories(skipTotal) {
      this.correctionFactor = 1
      let diff = this.carbs - this.fiber
      this.netcarbs = diff > 0 ? diff : 0
      this.proteins_calories = this.twoDigits(this.proteins * 4)
      this.carbs_calories = this.twoDigits(this.netcarbs * 4)
      this.fats_calories = this.twoDigits(this.fats * 9)
      this.sugara_calories = this.twoDigits(this.sugara * 2)
      this.fiber_calories = this.twoDigits(this.fiber * 2)

      if (skipTotal) {
        this.total_calories =
          this.nutrients.totalNutrients.ENERC_KCAL.quantity ??
          this.nutrients.totalNutrients.ENERC_KCAL
        let localTotal =
          this.proteins_calories +
          this.fats_calories +
          this.carbs_calories +
          this.sugara_calories +
          this.fiber_calories
        this.correctionFactor = this.total_calories / localTotal
      } else
        this.total_calories =
          this.proteins_calories +
          this.fats_calories +
          this.carbs_calories +
          this.sugara_calories +
          this.fiber_calories

      if (this.total_calories > 0) {
        this.proteins_percentage = this.twoDigits(
          (this.proteins_calories / this.total_calories) *
            this.correctionFactor *
            100
        )

        this.fats_percentage = this.twoDigits(
          (this.fats_calories / this.total_calories) *
            this.correctionFactor *
            100
        )
        const remainingPercentage = this.twoDigits(
          100 - this.proteins_percentage - this.fats_percentage
        )
        this.carbs_percentage =
          remainingPercentage > 0 ? remainingPercentage : 0
      } else {
        this.proteins_percentage = 0
        this.fats_percentage = 0
        this.carbs_percentage = 0
      }
    },

    favoritesLabel() {
      let start = 'Show Pantry? '
      let end = this.favoritesNum > 0 ? '(' + this.favoritesNum + ')' : ''
      return start + end
    },
    clearFavorites() {
      this.favoritesList = []
      this.favoritesNum = 0
      this.pantryOnly = false
    },
    toggleFavorites() {
      this.search = ''
      this.clearFoods()
      if (this.pantryOnly) this.loadFavorites()
      else {
        this.clearFavorites()
      }
    },
    addToPantry() {
      let label =
        this.selectedUnit == null ? 'Serving' : this.selectedUnit.label
      let weight = this.selectedUnit == null ? 0 : this.selectedUnit.weight
      let ingredients = null
      if (
        'saveIngredients' in this.nutrients &&
        'ingredients' in this.nutrients
      ) {
        ingredients = this.nutrients.ingredients
      }
      return axios
        .post(this.baseURL + '/nutrition/pantry', {
          unit: { label: label, weight: weight },
          qty: this.qty,
          name: this.mealName,
          proteins: this.proteins,
          fats: this.fats,
          carbs: this.carbs,
          sugara: this.sugara,
          netcarbs: this.netcarbs,
          fiber: this.fiber,
          calories: this.total_calories,
          fid: this.foodId,
          allnutrients:
            this.nutrients != null ? this.nutrients.totalNutrients : null,
          ingredients: ingredients
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.toast('Meal was succesfully added to pantry.')
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveMeal() {
      let dateObj = new Date(this.datetime)
      let mealTime = this.formatDateTime(dateObj)

      let mealName = 'NA'
      if (this.mealName.length > 1) mealName = this.mealName

      return axios
        .post(this.baseURL + '/nutrition/meal', {
          barcode: this.barcode,
          type: this.selectedMealType.meal,
          reuse: this.reUse,
          unit: this.selectedUnit,
          qty: this.qty,
          name: mealName,
          fid: this.foodId,
          proteins: this.proteins,
          fats: this.fats,
          carbs: this.carbs,
          sugara: this.sugara,
          netcarbs: this.netcarbs,
          fiber: this.fiber,
          calories: this.total_calories,
          correction_factor: this.correctionFactor,
          allnutrients:
            this.nutrients != null ? this.nutrients.totalNutrients : null,
          meal_time: mealTime,
          measures: this.measures,
          default_measure: this.default_measure
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.toast('Meal was succesfully added.')
            window.postMessage('meal_added', '*')
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    clearFoods() {
      this.foodList = []
    },
    useMeal(item) {
      if (item.brand == 'BFitr') {
        let factor = this.selectedUnit.weight / 100
        for (const [key, value] of Object.entries(item.nutrients)) {
          this.nutrients.totalNutrients[key] = {}
          this.nutrients.totalNutrients[key].quantity =
            value * factor * this.qty
        }
        this.setNutrients()
        this.calculateCalories(false)
      } else this.getNutrients(item)
      this.clearFoods()
    },
    usePantryItems() {
      this.nutrients = Object.assign({})
      let selected = this.favoritesList.filter(el => el.selected == true)
      let t = this
      selected.forEach(element => {
        let el = t.normalizeNutrients(element)
        element = el
      })
      this.nutrients.totalNutrients = this.sumNutrients(selected)
      this.proteins = this.twoDigits(this.nutrients.totalNutrients['PROCNT'])
      this.fats = this.twoDigits(this.nutrients.totalNutrients['FAT'])
      this.carbs = this.twoDigits(this.nutrients.totalNutrients['CHOCDF'])
      this.sugara = this.twoDigits(
        this.nutrients.totalNutrients['Sugar.alcohol']
      )
      this.fiber = this.twoDigits(this.nutrients.totalNutrients['FIBTG'])
      this.calculateCalories(true)
      this.favoritesList = []
      this.pantryOnly = false
    },
    getNutrients(item) {
      this.loaded = false
      this.resetNutrients()
      return axios
        .post(this.baseURL + '/nutrition/nutrients', {
          ingredients: this.selectedUnit,
          qty: this.qty,
          foodID: item.foodId
        })
        .then(response => {
          if (response.status == 200) {
            this.nutrients = response.data.data
            this.setNutrients()
            this.calculateCalories(true)
            this.search = ''
            this.clearFoods()
            this.loaded = true
          } else {
            this.loaded = true
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadFavorites() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/nutrition/favorites', {})
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(item => {
              item.selected = false
              item.fraction = 1
            })
            this.favoritesList = response.data.data
            this.loadedFavoritesList = response.data.data
            this.favoritesNum = this.loadedFavoritesList.length

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    searchNutrients() {
      this.resetNutrients()
      if (this.search.length < 2) return
      this.loaded = false
      this.search = this.search.trim()
      this.nutrientsList = []

      return axios
        .post(this.baseURL + '/nutrition/recipe', {
          title: 'New Search',
          ingr: this.search
        })
        .then(response => {
          if (response.status == 200) {
            if (response.data.data.length == 0) this.noresults = true
            else {
              this.nutrients = response.data.data
              this.nutrients.saveIngredients = true
              this.total_calories = this.nutrients.totalNutrients.ENERC_KCAL
              this.fats_calories = this.nutrients.totalNutrientsKCal.FAT_KCAL
              this.carbs_calories = this.nutrients.totalNutrientsKCal.CHOCDF_KCAL
              this.proteins_calories = this.nutrients.totalNutrientsKCal.PROCNT_KCAL
              this.setNutrients()
              this.calculateCalories()
            }
            this.loaded = true
          } else {
            this.noresults = true
            this.loaded = true
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveBarcode(save) {
      if (save) {
        this.barcode = this.search
        this.showNutrients()
      } else this.barcode = null
      this.showSaveBarcodeOption = false
    },
    searchFood() {
      if (this.search.length < 2) return
      this.loaded = false
      this.search = this.search.trim()
      this.foodList = []
      this.selectedUnit = null
      this.qty = 1

      return axios
        .get(this.baseURL + '/nutrition/food/' + this.search, {})
        .then(response => {
          if (response.status == 200) {
            if (response.data.data.length == 0) this.noresults = true
            else {
              this.noresults = response.data.data.hints.length == 0
              for (const item of response.data.data.hints) {
                const nutrients = item.food.nutrients
                item.proteins = this.twoDigits(nutrients['PROCNT'])
                item.fats = this.twoDigits(nutrients['FAT'])
                item.carbs = this.twoDigits(nutrients['CHOCDF'])
                item.fiber = this.twoDigits(nutrients['FIBTG'])
                item.sugara = this.twoDigits(nutrients['Sugar.alcohol'])
                item.calories = this.twoDigits(nutrients['ENERC_KCAL'])
                item.selected = false
              }
              this.foodList = response.data.data.hints
            }
            this.loaded = true
            this.clearFavorites()
            if (this.foodList.length == 0 && this.barcodeMode) {
              this.showSaveBarcodeOption = true
              this.barcodeMode = false
            } else this.showSaveBarcodeOption = false
          } else {
            this.noresults = true
            this.loaded = true
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMealTypes() {
      {
        return axios
          .get(this.baseURL + '/nutrition/mealtypes', {})
          .then(response => {
            let sel = null
            if (response.status == 200) {
              this.mealTypes = response.data.data
              sel = this.mealTypes[0]
              this.mealTypes.forEach(el => {
                if (
                  el.primary == 'Yes' &&
                  moment().isBetween(
                    moment(el.start, 'hh:mm:ss'),
                    moment(el.end, 'hh:mm:ss')
                  )
                )
                  sel = el
              })

              this.selectedMealType = sel
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
<style scoped>
.edamam {
  z-index: 99;
  position: absolute;
  right: 45px;
  top: 10px;
}
</style>
